.main-container{
    display: flex;
    flex-direction: column;
    width: 80%;
    max-height: 100vh;
    overflow: hidden;
}
.header-main{
    display: flex;
    align-items: center;
    height: 10%;
}
.input-message{
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    padding: 1rem;
}
#input-bar{
    flex-grow: 1;
    margin-right: 0.5rem;
    padding: '0.2rem 0.5rem';
    border-radius: '0.5rem';
}